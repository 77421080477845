if (!window.HTMLPictureElement) {
    import('picturefill');
}

import 'popper.js';
import 'bootstrap/js/dist/alert';
// import 'bootstrap/js/dist/button';
// import 'bootstrap/js/dist/carousel';
import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/dropdown';
import 'bootstrap/js/dist/modal';
// import 'bootstrap/js/dist/popover';
// import 'bootstrap/js/dist/scrollspy';
// import 'bootstrap/js/dist/tab';
// import 'bootstrap/js/dist/toast';
import 'bootstrap/js/dist/tooltip';

let _config = window['_config'] || {};

let app = {
    debug: false,
};
window.app = app || {};
window.app.modules =  app.modules || {};

$.loadScript = function (url) {
    console.warn('\'$.loadScript\' is going to be deprecated and should not be used anymore. Please use \'import\' for local files or import and use \'loadScript\' for external resources.');

    return import('@elements/load-script').then(function (loadScript) {
        return loadScript.default(url);
    });
};

import * as menuAim from './menu-aim';
app.modules.menuAim = menuAim.initInScope;

import * as touchNav from './touch-nav';
app.modules.touchNav = touchNav.initInScope;

import * as nav from './nav';
app.modules.nav = nav.initInScope;

$(document).ready(function() {
    let scrollContainer = $('.js-breadcrumbs__scroll');
    if (scrollContainer.length > 0) {
        scrollContainer.scrollLeft(scrollContainer.prop('scrollWidth'));
    }
});

import * as renderTemplate from './render-template';
app.modules.renderTemplate = renderTemplate.initInScope;

import * as goToLink from '@elements/go-to-link';
app.goToLink = goToLink.init;

import * as toc from '@elements/toc';
app.toc = toc.init;
app.modules.toc = toc.initInScope;

import * as findologic from './findologic';
app.modules.findologic = findologic.initInScope;

import * as overlay from './overlay';
app.overlay = overlay.init;
app.modules.overlay = overlay.initInScope;

import * as general from './general';
app.modules.general = general.initInScope;


import * as cart from './cart';
app.cart = cart.init;
app.modules.cart = cart.initInScope;

import * as affix from '@elements/affix';
app.modules.affix = affix.initInScope;

import * as collapsingRadio from './collapsingRadio';
app.modules.collapsingRadio = collapsingRadio.initInScope;

app.modules.navAffix = affix.createInitInScope(
    {
        mediaQuery: '(min-width: 768px)'
    },
    {
        base: '.js-nav-affix',
        placeholder: '.js-affix__placeholder',
        container: '.js-affix__container'

    });

import * as affixNavbar from './affix-navbar';
app.affixNavbar = affixNavbar.init;

import * as matchmediaViewer from "./matchmedia-viewer";
app.matchmediaViewer = matchmediaViewer.init;
app.modules.matchmediaViewer = matchmediaViewer.initInScope;

import * as reloadForm from "@elements/reload-form";
app.modules.reloadForm = reloadForm.initInScope;

import * as popinModal from './popin-modal';
app.popinModal = popinModal.init;

import * as searchBar from './searchBar';
app.modules.searchBar = searchBar.initInScope;

import * as wishlist from './wishlist';
app.wishlist = wishlist.init;
app.modules.wishlist = wishlist.initInScope;

import * as lazyImg from './lazy-img';
app.modules.lazyImg = lazyImg.initInScope;

import * as comparelist from './compare-list';
app.comparelist = comparelist.init;
app.modules.comparelist = comparelist.initInScope;

import * as reservationSelect from './reservation-select';
app.modules.reservationSelect = reservationSelect.initInScope;

import * as consentOverlay from './consent-overlay';
app.modules.consentOverlay = consentOverlay.initInScope;
